import React from "react";
import { NavLink } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import backgroundImage from "../../assets/images/office.jpg"; // Import your background image
import backgroundVideo from "../../assets/videos/landing.mp4"; // Import your background video

const Home = () => {
    const isMobileDevice = (): boolean => {
        const userAgent = typeof window !== 'undefined' ? navigator.userAgent : '';
        return /android|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(userAgent);
    };

    return (
        <React.Fragment>
            {/*{!isMobileDevice() && (*/}
                <section className={`section ${isMobileDevice() ? 'job-hero-section2' : 'job-hero-section'} bg-light pb-0`} id="hero">
                    <Container fluid={true} style={{
                        position: 'relative', // Ensure the container is positioned relative
                        padding: '50px', // Optional: Add padding if needed
                    }}>
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundImage: `url(${backgroundImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            filter: 'brightness(35%)',
                        }}></div>
                        <Row className="justify-content-between align-items-center text-center" style={{ marginTop: "80px", marginBottom: "80px", color: "white" }}>
                            <Col lg={12} className="text-center">
                                <div>
                                    <h1 className="display-2 fw-semibold text-capitalize mb-3 lh-base text-white">
                                        ClvrPay
                                    </h1>
                                    <h2 className="display-6 fw-semibold text-capitalize lh-base text-white">
                                        Simplify Your Payments with Our Innovative Payment Solution
                                    </h2>
                                    <p className="lead lh-base mb-4 text-white">
                                        Secure, efficient, and tailored payment solutions for your business
                                    </p>
                                    <div className="mt-5">
                                        <NavLink to="https://merchant.clvrpay.com/register" className="btn btn-soft-primary">
                                            <i className="ri-user-3-line align-bottom "></i> Become A Merchant
                                        </NavLink>
                                        <NavLink to="https://reseller.clvrpay.com/register"
                                                 className={`btn btn-soft-primary ${isMobileDevice() ? 'mt-2' : 'ms-4'}`}>
                                            <i className="ri-user-3-line align-bottom"></i> Become A Reseller
                                        </NavLink>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            {/*)}*/}
            {/*{!isMobileDevice() && (*/}
            {/*    <section*/}
            {/*        className={`section ${isMobileDevice() ? 'job-hero-section2' : 'job-hero-section'} bg-light pb-0`}*/}
            {/*        id="hero" style={{height: "100%"}}>*/}
            {/*        <Container fluid={true} style={{*/}
            {/*            position: 'relative',*/}
            {/*            padding: '50px',*/}
            {/*            height: '80vh', // Set container height to 100% of viewport height*/}
            {/*        }}>*/}
            {/*            <video autoPlay muted loop style={{*/}
            {/*                position: 'absolute',*/}
            {/*                top: 0,*/}
            {/*                left: 0,*/}
            {/*                width: '100%',*/}
            {/*                height: '100%',*/}
            {/*                objectFit: 'cover',*/}
            {/*            }}>*/}
            {/*                <source src={backgroundVideo} type="video/mp4"/>*/}
            {/*                Your browser does not support the video tag.*/}
            {/*            </video>*/}
            {/*            <Row className="justify-content-between align-items-center text-center"*/}
            {/*                 style={{height: '100%', color: "white"}}>*/}
            {/*                <Col lg={12} className="text-center">*/}
            {/*                    <div className="d-flex flex-column justify-content-between h-100">*/}
            {/*                        <div className="mt-5 align-self-end" style={{ position: 'absolute', bottom: '-300px', left: '50%', transform: 'translateX(-50%)' }}>*/}
            {/*                            <NavLink to="https://merchant.clvrpay.com/register" className="btn btn-soft-primary">*/}
            {/*                                <i className="ri-user-3-line align-bottom "></i> Become A Merchant*/}
            {/*                            </NavLink>*/}
            {/*                            <NavLink to="https://reseller.clvrpay.com/register"*/}
            {/*                                     className={`btn btn-soft-primary ${isMobileDevice() ? 'mt-2' : 'ms-4'}`}>*/}
            {/*                                <i className="ri-user-3-line align-bottom"></i> Become A Reseller*/}
            {/*                            </NavLink>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*        </Container>*/}
            {/*    </section>*/}
            {/*)}*/}
        </React.Fragment>
    );
};

export default Home;
