import img2 from "../../assets/images/companies/img-2.png";
import img3 from "../../assets/images/companies/img-3.png";
import img4 from "../../assets/images/companies/img-4.png";
import img9 from "../../assets/images/companies/img-9.png";

const features = [
  {
    id: 1,
    title : "Security",
    clss: "shadow-lg",
    desc: "Top-notch security to protect your transactions and data.",
  },
  {
    id: 2,
    title : "Efficiency",
    clss: "shadow-lg",
    desc: "Streamlined payment processes for faster transactions.",
  },
  {
    id: 3,
    title : "Customization",
    clss: "shadow-lg",
    desc: "Tailored solutions to meet your specific business needs.",
  },
  {
    id: 4,
    title : "Cost-Effective",
    clss: "shadow-lg",
    desc: "Reduce transaction fees and improve your bottom line.",
  }
];

const categories = [
  {
    id: 1,
    icon: "ri-pencil-ruler-2-line fs-1",
    lable: "IT & Software",
    jobs: "1543 Jobs",
  },
  {
    id: 2,
    icon: "ri-airplay-line fs-1",
    lable: "Construction / Facilities",
    jobs: "3241 Jobs",
  },
  {
    id: 3,
    icon: "ri-bank-line fs-1",
    lable: "Government",
    jobs: "876 Jobs",
  },
  {
    id: 4,
    icon: "ri-focus-2-line fs-1",
    lable: "Marketing & Advertising",
    jobs: "465 Jobs",
  },
  {
    id: 5,
    icon: "ri-pencil-ruler-line fs-1",
    lable: "Education & training",
    jobs: "105 Jobs",
  },
  {
    id: 6,
    icon: "ri-line-chart-line fs-1",
    lable: "Digital Marketing",
    jobs: "377 Jobs",
  },
  {
    id: 7,
    icon: "ri-briefcase-2-line fs-1",
    lable: "Catering & Tourism",
    jobs: "85 Jobs",
  },
];
const benefits = [
  {
    id: 1,
    company: img3,
    lable: "Enhanced Control",
    desc : "Complete control over the payment process within your network.",
    name: "Nesta Technologies",
    location: "USA",
    salary: "$23k - 35k",
    bookmark: false,
    s1: ["Design", "success"],
    s2: ["UI/UX", "danger"],
    s3: ["Adobe XD", "primary"],
  },
  {
    id: 2,
    company: img2,
    lable: "Increased Loyalty",
    desc : "Build customer loyalty with seamless and exclusive payment options.",
    class: "active",
    name: "Digitech Galaxy",
    location: "Spain",
    salary: "$10k - 15k",
    bookmark: true,
    s1: ["Sales", "primary"],
    s2: ["Product", "secondary"],
    s3: ["Business", "info"],
  },
  {
    id: 3,
    company: img4,
    lable: "Reduced Fraud",
    desc : "Minimized risk of fraud with a secure, closed network.",
    class: "active",
    name: "Meta4Systems",
    location: "Sweden",
    salary: "$20k - 25k",
    bookmark: true,
    s1: ["Marketing", "warning"],
    s2: ["Business", "info"],
  },
  {
    id: 4,
    company: img9,
    lable: "Data Insights",
    desc : "Gain valuable insights into transaction data to optimize operations.",
    name: "Themesbrand",
    location: "USA",
    salary: "$40k+",
    bookmark: false,
    s1: ["Design", "success"],
    s2: ["UI/UX", "danger"],
    s3: ["Adobe XD", "primary"],
  },
];
export { features, categories, benefits };
