import React, {useEffect, useState} from "react";
import { NavLink, Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import LogoLight from "../../assets/images/logo-light.png";
import Logo1 from "../../assets/images/logo1.png";
const Footer = () => {
  const [currentYear,setCurrentYear] = useState<any>(2024)
  useEffect(() => {
    setCurrentYear(String(new Date().getFullYear()));
  },[])

  return (
    <React.Fragment>
      <footer className="custom-footer bg-dark py-5 position-relative">
        <Container>
          <Row>
            <Col lg={12} className="mt-4 text-center">
              <div>
                <div className=" fs-20">
                  <p>
                    We specialize in providing innovative Innovative Payment Solution gift card solutions that empower businesses to enhance customer loyalty, increase revenue, and promote brand awareness. Our user-friendly platform ensures secure and efficient management of gift card issuance and redemption. Join us in revolutionizing the way businesses connect with their customers.
                  </p>
                </div>
              </div>
            </Col>
            {/*<Col lg={7} className="ms-lg-auto">*/}
            {/*  <Row>*/}
            {/*    <Col sm={4} className="mt-4">*/}
            {/*      <h5 className="text-white mb-0">Company</h5>*/}
            {/*      <div className="text-muted mt-3">*/}
            {/*        <ul className="list-unstyled ff-secondary footer-list fs-14">*/}
            {/*          <li>*/}
            {/*            <NavLink to="/#hero">Home</NavLink>*/}
            {/*          </li>*/}
            {/*          <li>*/}
            {/*            <NavLink to="/pages-gallery">Gallery</NavLink>*/}
            {/*          </li>*/}
            {/*          <li>*/}
            {/*            <NavLink to="/pages-team">Team</NavLink>*/}
            {/*          </li>*/}
            {/*          <li>*/}
            {/*            <NavLink to="/pages-pricing">Pricing</NavLink>*/}
            {/*          </li>*/}
            {/*          <li>*/}
            {/*            <NavLink to="/pages-timeline">Timeline</NavLink>*/}
            {/*          </li>*/}
            {/*        </ul>*/}
            {/*      </div>*/}
            {/*    </Col>*/}
            {/*    <Col sm={4} className="mt-4">*/}
            {/*      <h5 className="text-white mb-0">For Jobs</h5>*/}
            {/*      <div className="text-muted mt-3">*/}
            {/*        <ul className="list-unstyled ff-secondary footer-list fs-14">*/}
            {/*          <li>*/}
            {/*            <NavLink to="/apps-job-lists">Job List</NavLink>*/}
            {/*          </li>*/}
            {/*          <li>*/}
            {/*            <NavLink to="/apps-job-application">*/}
            {/*              application*/}
            {/*            </NavLink>*/}
            {/*          </li>*/}
            {/*          <li>*/}
            {/*            <NavLink to="/apps-job-new">New Job</NavLink>*/}
            {/*          </li>*/}
            {/*          <li>*/}
            {/*            <NavLink to="/apps-job-companies-lists">*/}
            {/*              Company List*/}
            {/*            </NavLink>*/}
            {/*          </li>*/}
            {/*        </ul>*/}
            {/*      </div>*/}
            {/*    </Col>*/}
            {/*    <Col sm={4} className="mt-4">*/}
            {/*      <h5 className="text-white mb-0">Support</h5>*/}
            {/*      <div className="text-muted mt-3">*/}
            {/*        <ul className="list-unstyled ff-secondary footer-list fs-14">*/}
            {/*          <li>*/}
            {/*            <NavLink to="/pages-faqs">Contact</NavLink>*/}
            {/*          </li>*/}
            {/*        </ul>*/}
            {/*      </div>*/}
            {/*    </Col>*/}
            {/*  </Row>*/}
            {/*</Col>*/}
          </Row>

          <Row className="text-center text-sm-start align-items-center">
            <Col sm={12} lg={12} md={12} className={"text-center"}>
              <div>
                <p className="copy-rights mb-0">
                  <script> document.write(new Date().getFullYear()) </script>
                  {currentYear} © ClvrPay
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
