import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import { benefits } from "../../common/data/jobLanding";

const Benefits = () => {
  const [isBookmarkClick, setIsBookmarkClick] = useState<boolean>(false);

  return (
    <React.Fragment>
      <section className="section bg-light" id="benefits">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="text-center mb-5">
                <h1 className="mb-3 ff-secondary text-capitalize lh-base display-6">
                  ClvrPay Benefits
                </h1>
                <p className="text-muted fs-18">
                  Benefits of ClvrPay Innovative Payment Solution
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            {benefits.map((item : any, key : any) => (
              <Col lg={6} key={key} className={"mt-3"}>
                <Card className="shadow-lg h-100">
                  <CardBody>
                    <div className="d-flex">
                      <div className="avatar-sm">
                        <div className="avatar-title bg-warning-subtle rounded">
                          <img
                            src={item.company}
                            alt=""
                            className="avatar-xxs"
                          />
                        </div>
                      </div>
                      <div className="ms-3 flex-grow-1">
                        <Link to="#!">
                          <h5>{item.lable}</h5>
                        </Link>
                        <div className="hstack gap-2">
                          {item.desc}
                        </div>
                      </div>
                      <div>
                        <Link
                          type="button"
                          to="!#"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsBookmarkClick(!isBookmarkClick);
                          }}
                          className={
                            isBookmarkClick
                              ? "btn btn-ghost-primary btn-icon custom-toggle active"
                              : "btn btn-ghost-primary btn-icon custom-toggle"
                          }
                          data-bs-toggle="button"
                        >
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Benefits;
