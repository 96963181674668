import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import {Link} from "react-router-dom";
import {resellersList} from "../../common/data/reseller";

const Resellers = () => {
  const isMobileDevice = (): boolean => {
    const userAgent = typeof window !== 'undefined' ? navigator.userAgent : '';
    return /android|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(userAgent);
  };

  return (
    <React.Fragment>
      <section className="section" style={{backgroundColor:"#eceff1"}} id="resellers">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="text-center mb-5">
                <h1 className="mb-3  display-6 fw-semibold lh-base">
                  ClvrPay Resellers List
                </h1>
                <p className="text-muted fs-18">
                  You can buy ClvrPay Vouchers from our trusted list of resellers
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {resellersList.map(reseller => {
              return <div className="col-12 col-lg-3 col-xl-3 ">
                <Card className="text-center">
                  <CardBody className="p-4 justify-content-center">
                    <img
                        src={reseller.resellerLogo}
                        alt=""
                        className="rounded-3 avatar-lg mx-auto d-block"
                    />
                    <h5 className="fs-17 mt-4 mb-2">{reseller.resellerName}</h5>
                    <p className="text-muted fs-13 mb-3">
                      {reseller.resellerHasWebsite && <a href={reseller.resellerWebsite}>Visit Website</a>}
                      {!reseller.resellerHasWebsite && <a href={`tel:+${reseller.resellerPhone}`}>+{reseller.resellerPhone}</a>}
                    </p>
                    <p className="text-muted mb-4 fs-14">
                      <i className="ri-map-pin-2-line text-primary me-1 align-bottom"></i>{" "}
                      {reseller.resellerRegion}
                    </p>
                    {reseller.forceViewNumber && <Link to={`tel:+${reseller.resellerPhone}`} className="btn btn-primary w-100">
                      Call +{reseller.resellerPhone}
                    </Link>}
                    {reseller.resellerHasWebsite && !reseller.forceViewNumber && <Link to={reseller.resellerLinkToCheck} className="btn btn-primary w-100">
                      View Website
                    </Link>}
                    {!reseller.resellerHasWebsite && !reseller.forceViewNumber && <Link to={`tel:+${reseller.resellerPhone}`} className="btn btn-primary w-100">
                      Call +{reseller.resellerPhone}
                    </Link>}
                  </CardBody>
                </Card>
              </div>})}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Resellers;
