import React from 'react';
import './assets/scss/themes.scss';
import { Modal, ModalBody } from 'reactstrap';
import Route from './Routes';

function App() {

  return (
    <React.Fragment>
      <Modal id="myModal" className="modal-dialog-centered p-0" isOpen={false} toggle={() => {}}>
        <ModalBody className='p-0'>
        </ModalBody>
      </Modal>
      <Route />
    </React.Fragment>
  );
}

export default App;
