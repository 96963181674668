import Basic404 from "pages/AuthenticationInner/Errors/Basic404";
import Landing from "../pages/Job";

const authProtectedRoutes = [
  { path :"*",component: <Basic404/>}
];

const publicRoutes = [
  { path: "/",component: <Landing/>},
  {path :"*",component: <Basic404/>},
];

export { authProtectedRoutes, publicRoutes };