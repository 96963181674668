import { Container } from "reactstrap";
import React, { useState, ChangeEvent, FormEvent } from "react";
import * as yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";
import postApi from "../../apis/post.api";
import { toast } from "react-toastify";

// Define validation schema using Yup
const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email address").required("Email is required"),
    subject: yup.string().required("Subject is required"),
    comments: yup.string().required("Message is required"),
});

const Contact = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        subject: "",
        comments: ""
    });

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        subject: "",
        comments: ""
    });

    const [isFormValid, setIsFormValid] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Validate the field on change
        validationSchema
            .validateAt(name, { [name]: value })
            .then(() => setErrors({ ...errors, [name]: "" }))
            .catch(err => setErrors({ ...errors, [name]: err.message }));
    };

    const handleRecaptchaChange = (token: string | null) => {
        setRecaptchaToken(token);
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        validationSchema.validate(formData, { abortEarly: false })
            .then(() => {
                if (recaptchaToken) {
                    postApi('/common/contact', { ...formData, recaptchaToken }).then((response: any) => {
                        toast.success('Your message has been sent successfully');
                    }).catch((error: any) => {
                        toast.error('There was an issue while sending your message, please try again');
                    });
                } else {
                    toast.error('Please complete the reCAPTCHA');
                }
            })
            .catch(err => {
                const validationErrors: any = {};
                err.inner.forEach((error: yup.ValidationError) => {
                    validationErrors[error.path!] = error.message;
                });
                setErrors(validationErrors);
            });
    };

    // Validate form on each change
    React.useEffect(() => {
        validationSchema.isValid(formData).then(valid => setIsFormValid(valid));
    }, [formData]);

    return (
        <React.Fragment>
            <section className="section" id="contact">
                <Container>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="text-center mb-5">
                                <h3 className="mb-3 fw-semibold">Get In Touch</h3>
                                <p className="text-muted mb-4 ff-secondary">
                                    Interested in exploring business partnerships? We’re always looking for opportunities to collaborate and grow together. Whether you’re a digital cards reseller, or a merchant who would like to accept payments in ClvrPay vouchers, we’d love to hear from you.
                                    Reach out to discuss how we can create value together and achieve mutual success. Let’s build something great!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-4">
                        <div className="col-lg-4">
                            <div>
                                <div className="mt-4">
                                    <h5 className="fs-13 text-muted text-uppercase">Phone Numbers Whatsapp:</h5>
                                    <div className="ff-secondary fw-semibold">
                                        (UAE) <img alt="clvrPay Whatsapp" className="w-20px"
                                                   src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1024px-WhatsApp.svg.png"/>
                                        <a href="https://api.whatsapp.com/send/?phone=971561438878&text&type=phone_number&app_absent=0">+971
                                            56 143 8878</a>
                                        <br/>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <h5 className="fs-13 text-muted text-uppercase">Phone Numbers Call:</h5>
                                    <div className="ff-secondary fw-semibold">
                                        (UAE) <img alt="clvrPay Whatsapp" className="w-20px pe-1" src="https://www.svgrepo.com/show/111201/phone-call.svg"/>
                                            +971 56 143 8878
                                        <br/>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <h5 className="fs-13 text-muted text-uppercase">Email :</h5>
                                    <div className="ff-secondary fw-semibold">info@clvrpay.com</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label htmlFor="name" className="form-label fs-13">Name</label>
                                                <input
                                                    name="name"
                                                    id="name"
                                                    type="text"
                                                    className="form-control bg-light border-light"
                                                    placeholder="Your name*"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                                {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label htmlFor="email" className="form-label fs-13">Email</label>
                                                <input
                                                    name="email"
                                                    id="email"
                                                    type="email"
                                                    className="form-control bg-light border-light"
                                                    placeholder="Your email*"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                                {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="mb-4">
                                                <label htmlFor="subject" className="form-label fs-13">Subject</label>
                                                <input
                                                    type="text"
                                                    className="form-control bg-light border-light"
                                                    id="subject"
                                                    name="subject"
                                                    placeholder="Your Subject.."
                                                    value={formData.subject}
                                                    onChange={handleChange}
                                                />
                                                {errors.subject && <div className="text-danger fs-12">{errors.subject}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="mb-3">
                                                <label htmlFor="comments" className="form-label fs-13">Message</label>
                                                <textarea
                                                    name="comments"
                                                    id="comments"
                                                    rows={3}
                                                    className="form-control bg-light border-light"
                                                    placeholder="Your message..."
                                                    value={formData.comments}
                                                    onChange={handleChange}
                                                ></textarea>
                                                {errors.comments && <div className="text-danger fs-12">{errors.comments}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 text-end">
                                            <ReCAPTCHA
                                                sitekey={process.env.REACT_APP_GOOGLE_CAPCHA ?? ''} // Replace with your reCAPTCHA site key
                                                onChange={handleRecaptchaChange}
                                            />
                                            <input
                                                type="submit"
                                                id="submit"
                                                name="send"
                                                className="submitBnt btn btn-primary mt-3"
                                                value="Send Message"
                                                disabled={!isFormValid || !recaptchaToken}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default Contact;
