import reseller1 from "../../assets/images/resellers/1.jpeg";
import reseller2 from "../../assets/images/resellers/2.jpeg";
import reseller3 from "../../assets/images/resellers/3.jpeg";
import reseller4 from "../../assets/images/users/user-dummy-img.jpg"
import reseller5 from "../../assets/images/resellers/4.png"
import reseller6 from "../../assets/images/resellers/6.jpeg"
import reseller7 from "../../assets/images/resellers/7.jpeg"
import reseller8 from "../../assets/images/resellers/8.png"
import reseller9 from "../../assets/images/resellers/9.png"
import reseller11 from "../../assets/images/resellers/11.png"
import reseller12 from "../../assets/images/resellers/12.jpeg"

export const resellersList = [
    {
        "resellerId" : 1,
        "resellerName" : "EGIFTLY LTD",
        "resellerWebsite" : "https://e-giftly.com/brand/156/clvrpay/",
        "resellerLogo" : reseller1,
        "resellerRegion" : "Global",
        "resellerLinkToCheck" : "https://e-giftly.com/brand/156/clvrpay/",
        "resellerPhone" : "",
        "resellerHasWebsite" : true,
        "resellerHasLogo" : true,
        "forceViewNumber" : false
    },
    {
        "resellerId" : 2,
        "resellerName" : "EgyCards",
        "resellerWebsite" : "https://egycards.com/product-category/payment-cards/clvr-pay/",
        "resellerLogo" : reseller7,
        "resellerRegion" : "Global",
        "resellerLinkToCheck" : "https://egycards.com/product-category/payment-cards/clvr-pay/",
        "resellerPhone" : "",
        "resellerHasWebsite" : true,
        "resellerHasLogo" : true,
        "forceViewNumber" : false
    },
    {
        "resellerId" : 3,
        "resellerName" : "Gold4Cards",
        "resellerWebsite" : "https://gold4cards.com/shop/categories/payment-cards/clvrpay-vouchers",
        "resellerLogo" : reseller2,
        "resellerRegion" : "Global",
        "resellerLinkToCheck" : "https://gold4cards.com/shop/categories/payment-cards/clvrpay-vouchers",
        "resellerPhone" : "",
        "resellerHasWebsite" : true,
        "resellerHasLogo" : true,
        "forceViewNumber" : false
    },
    {
        "resellerId" : 4,
        "resellerName" : "Anis",
        "resellerWebsite" : " https://anis.ly/",
        "resellerLogo" : reseller5,
        "resellerRegion" : "Libya",
        "resellerLinkToCheck" : " https://anis.ly/",
        "resellerPhone" : "",
        "resellerHasWebsite" : true,
        "resellerHasLogo" : true,
        "forceViewNumber" : false
    },
    {
        "resellerId" : 5,
        "resellerName" : "Friendly Services",
        "resellerWebsite" : "https://fs-card.com",
        "resellerLogo" : reseller6,
        "resellerRegion" : "Yemen | Iraq | Jordan",
        "resellerLinkToCheck" : "https://fs-card.com",
        "resellerPhone" : "9647724777705",
        "resellerHasWebsite" : true,
        "resellerHasLogo" : true,
        "forceViewNumber" : false
    },
    {
        "resellerId" : 6,
        "resellerName" : "Ghezali Services DZ",
        "resellerWebsite" : "https://www.facebook.com/GHEZALI1980?mibextid=ZbWKwL",
        "resellerLogo" : reseller3,
        "resellerRegion" : "Algeria",
        "resellerLinkToCheck" : "https://www.facebook.com/GHEZALI1980?mibextid=ZbWKwL",
        "resellerPhone" : "213770492796",
        "resellerHasWebsite" : true,
        "resellerHasLogo" : true,
        "forceViewNumber" : false
    },
    {
        "resellerId" : 8,
        "resellerName" : "Lozpay",
        "resellerWebsite" : "https://lozpay.com/",
        "resellerLogo" : reseller8,
        "resellerRegion" : "IRAQ",
        "resellerLinkToCheck" : "https://lozpay.com/",
        "resellerPhone" : "447455282639",
        "resellerHasWebsite" : true,
        "resellerHasLogo" : true,
        "forceViewNumber" : true
    },
    {
        "resellerId" : 9,
        "resellerName" : "Sadad 360 ",
        "resellerWebsite" : "https://play.google.com/store/apps/details?id=sadad.s2d.com&pli=1",
        "resellerLogo" : reseller9,
        "resellerRegion" : "Yemen",
        "resellerLinkToCheck" : "https://play.google.com/store/apps/details?id=sadad.s2d.com&pli=1",
        "resellerPhone" : "",
        "resellerHasWebsite" : true,
        "resellerHasLogo" : true,
        "forceViewNumber" : false
    },
    {
        "resellerId" : 11,
        "resellerName" : "GameJo",
        "resellerWebsite" : "https://www.gamecard.fun/",
        "resellerLogo" : reseller11,
        "resellerRegion" : "JORDAN",
        "resellerLinkToCheck" : "https://www.gamecard.fun/",
        "resellerPhone" : "",
        "resellerHasWebsite" : true,
        "resellerHasLogo" : true,
        "forceViewNumber" : false
    },
    {
        "resellerId" : 11,
        "resellerName" : "Twiintech",
        "resellerWebsite" : "https:/store.twiintech.com/",
        "resellerLogo" : reseller12,
        "resellerRegion" : "Saudi Arabia",
        "resellerLinkToCheck" : "https:/store.twiintech.com/",
        "resellerPhone" : "966553518418",
        "resellerHasWebsite" : true,
        "resellerHasLogo" : true,
        "forceViewNumber" : true
    },
    {
        "resellerId" : 7,
        "resellerName" : "Ameen Tech",
        "resellerWebsite" : "",
        "resellerLogo" : reseller4,
        "resellerRegion" : "IRAQ",
        "resellerLinkToCheck" : "",
        "resellerPhone" : "9647724777705",
        "resellerHasWebsite" : false,
        "resellerHasLogo" : false,
        "forceViewNumber" : false
    },
    {
        "resellerId" : 10,
        "resellerName" : "Mohmmed Jannoun",
        "resellerWebsite" : "",
        "resellerLogo" : reseller4,
        "resellerRegion" : "JORDAN",
        "resellerLinkToCheck" : "",
        "resellerPhone" : "962795368814",
        "resellerHasWebsite" : false,
        "resellerHasLogo" : false,
        "forceViewNumber" : false
    },
]