import React from 'react';
import { Routes, Route, Link } from "react-router-dom";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";

//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Index = () => {
    return (
        <React.Fragment>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Routes>
                <Route>
                    {publicRoutes.map((route: { path: string | undefined; component: any; }, idx: React.Key | null | undefined) => (
                        <Route path={route.path}
                               element={
                                   <NonAuthLayout>
                                       {route.component}
                                   </NonAuthLayout>
                               }
                               key={idx}
                        />
                    ))}
                </Route>
            </Routes>
        </React.Fragment>
    );
};

export default Index;