import React from "react";
import { Col, Container, Row } from "reactstrap";
import voucher from "../../assets/images/voucher2.png";

const Features = () => {
  return (
    <React.Fragment>
      <section className="section" style={{backgroundColor:"#eceff1"}} id="features">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col lg={12}>
              <div className={"text-center mb-5"}>
                <img src={voucher} alt="voucher" className="w-25 "/>
              </div>
              <div className="mb-5 mt-5 fs-4">
                  ClvrPay is an advanced online payment system designed around prepaid vouchers. Its powerful platform
                  seamlessly connects merchants and resellers through API integration, allowing merchants to securely
                  process payments from their online customers with wider exposure.
              </div>
              <div className="mb-5 fs-4">
                  ClvrPay gift cards consist of 16-digit pin codes. Users can redeem them directly at all our partner
                  merchant websites.
              </div>
            </Col>

          </Row>


        </Container>
      </section>
    </React.Fragment>
  );
};

export default Features;
