import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import {
  Collapse,
  Container,
  NavbarToggler,
  NavLink as TabLink,
} from "reactstrap";
import Logo1 from "../../assets/images/logo.png";

const Navbar = () => {
  const [isOpenMenu, setisOpenMenu] = useState<boolean>(false);
  const [navClass, setnavClass] = useState<any>("");

  const toggle = () => setisOpenMenu(!isOpenMenu);

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
    handleInitialScroll(); // Check hash when the component mounts
    return () => {
      window.removeEventListener("scroll", scrollNavigation);
    };
  }, []);

  const [activeLink, setActiveLink] = useState<any>();

  useEffect(() => {
    const activation = (event: any) => {
      const target = event.target;
      if (target) {
        target.classList.add("active");
        setActiveLink(target);
        if (activeLink && activeLink !== target) {
          activeLink.classList.remove("active");
        }
      }
    };
    const defaultLink = document.querySelector(".navbar li a.active");
    if (defaultLink) {
      defaultLink?.classList.add("active");
      setActiveLink(defaultLink);
    }
    const links = document.querySelectorAll(".navbar a");
    links.forEach((link) => {
      link.addEventListener("click", activation);
    });
    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", activation);
      });
    };
  }, [activeLink]);

  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setnavClass(" is-sticky");
    } else {
      setnavClass("");
    }
  };

  const handleInitialScroll = () => {
    const hash = window.location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <React.Fragment>
      <nav
        className={
          "navbar navbar-expand-lg navbar-landing fixed-top job-navbar" +
          navClass
        }
        id="navbar"
      >
        <Container fluid className="custom-container">
          <NavLink className="navbar-brand" to="/">
            <img
              src={Logo1}
              className="card-logo card-logo-dark"
              alt="logo dark"
              height="70"
            />
          </NavLink>
          <NavbarToggler
            onClick={toggle}
            className="navbar-toggler py-0 fs-20 text-body"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="mdi mdi-menu"></i>
          </NavbarToggler>

          <Collapse className="navbar-collapse" isOpen={isOpenMenu} id="navbarSupportedContent">
            <Scrollspy
              offset={-18}
              items={[
                "hero",
                "features",
                "benefits",
                "contact",
              ]}
              currentClassName="active"
              className="navbar-nav mx-auto mt-2 mt-lg-0"
              id="navbar-example"
            >
              <li className="nav-item">
                <TabLink className="nav-link fs-18 active" href="#hero">
                  Home
                </TabLink>
              </li>
              <li className="nav-item">
                <TabLink className="nav-link fs-18" href="#features">
                  Features
                </TabLink>
              </li>
              <li className="nav-item">
                <TabLink className="nav-link fs-18" href="#benefits">
                  Benefits
                </TabLink>
              </li>
              <li className="nav-item">
                <TabLink className="nav-link fs-18" href="#contact">
                  Contact Us
                </TabLink>
              </li>
              <li className="nav-item">
                <TabLink className="nav-link fs-18" href="#resellers">
                  Official Resellers
                </TabLink>
              </li>
            </Scrollspy>

            <div>
              <NavLink to="https://merchant.clvrpay.com/login" className="btn btn-soft-primary">
                Merchant Login
              </NavLink>
              <NavLink to="https://reseller.clvrpay.com/login" className="btn btn-soft-primary ms-2">
                Reseller Login
              </NavLink>
            </div>
          </Collapse>
        </Container>
      </nav>
    </React.Fragment>
  );
};

export default Navbar;
