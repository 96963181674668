import axios from "axios";

interface IRequest {
    get: (url: string) => Promise < any > 
    delete: (url: string) => Promise < any > 
    post: (url: string, body: any) => Promise < any >
    put: (url: string, body: any) => Promise < any >
}

export default class Api {
    static getInstance():{request:IRequest} {
        const originalAxios = axios.create({
            baseURL:  process.env.REACT_APP_API_URL,
            timeout: Number(process.env.REACT_APP_API_TIMEOUT || '5000')
        });
        const defaultHeaders = {
            'accept-language': 'ar',
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('admin_access_token')
        };

        const request: IRequest = {
            get: async function (url: string) {
                return originalAxios.get(url, {
                    headers: defaultHeaders,
                    withCredentials: true
                }).catch(error => {
                    throw error;
                });
            },
            delete: async function (url: string) {
                return originalAxios.delete(url, {
                    headers: defaultHeaders,
                    withCredentials: true
                }).catch(error => {
                    throw error;
                });
            },
            post: async function (url: string, data:any) {
                const response = await originalAxios.post(url, data,{
                    headers: defaultHeaders,
                    withCredentials: true
                }).catch(error => {
                    throw error;
                });
                return response;
            },
            put: async function (url: string, data: any) {
                const response = await originalAxios.put(url, data,{
                    headers: defaultHeaders,
                    withCredentials: true
                }).catch(error => {
                    throw error;
                });

                return response;
            },
        }
        return {
            request
        }
    }
}